import styled from "styled-components";
import NavbarAll from '../NavbarAll/NavbarAllFiles';
const StyledContainer = styled.div`
  margin-top: 20px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const StyledCard = styled.div`
  width: 100%;
  max-width: 300px; /* Set a maximum width for better responsiveness */

  @media (min-width: 600px) {
    width: calc(33.33% - 20px); /* Display a maximum of 3 cards in one row with some spacing */
    margin: 10px;
    max-width: none; /* Remove max-width for larger screens */
  }

  margin: 10px auto; /* Center the cards horizontally on mobile devices */
  background-color: black; /* Set a white background for better visibility */
  border-radius: 8px; /* Add border-radius for a rounded appearance */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added a subtle box shadow for a card-like appearance */
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05); /* Add a slight scale effect on hover */
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  border-top-left-radius: 8px; /* Adjust border-radius to match the card's border-radius */
  border-top-right-radius: 8px;
`;

const StyledCardContent = styled.div`
  padding: 16px;
`;

const StyledTypography = styled.span`
  font-family: 'Arial';
  font-size: 15px;
  text-align: left;
  color: white; /* Adjusted text color to a lighter dark gray for better contrast */
  margin-bottom: 8px; /* Add margin for spacing between title and content */
`;

export {
  StyledContainer,
  StyledDiv,
  StyledCard,
  StyledImage,
  StyledCardContent,
  StyledTypography,
};
 
const Activities = () => {
  return (
    <>
       
     <NavbarAll/>
    <StyledContainer >
      <StyledTypography>
        <StyledTypography variant="h5">
        </StyledTypography>
        <StyledDiv >
          
          <StyledCard >
            <StyledImage
             src="/assets/images/quran2.jpg" alt="Image Alt Text"
            />
            <StyledCardContent>
              <styledTypography variant="h6">Hifz and Nazra Classes</styledTypography>
            </StyledCardContent>
          </StyledCard>

          {/* Card 2 */}
          <StyledCard >
            <StyledImage
              
              alt="Image Alt Text"
             
              src="/assets/images/tafseer.jpeg" // Replace with your image URL
            />
            <StyledCardContent>
              <styledTypography variant="h6">Tafseer Quran</styledTypography>
              <styledTypography variant="body2" color="text.secondary">
                Friday After Isha 25 Minutes
              </styledTypography>
            </StyledCardContent>
          </StyledCard>

          {/* Card 3 */}
          <StyledCard style={{ width: { xs: '100%', sm: '48%' }, mt: 2 }}>
            <StyledImage
              component="img"
              alt="Image Alt Text"
              height="300"
              src="/assets/images/dars.jpg" // Replace with your image URL
            />
            <StyledCardContent>
              <styledTypography variant="h6">Dars-e-Quran</styledTypography>
              <styledTypography variant="body2" color="text.secondary">
                Sunday after zuhur 20 minutes
              </styledTypography>
            </StyledCardContent>
          </StyledCard>

          {/* Card 4 */}
          <StyledCard>
            <StyledImage
              component="img"
              alt="Image Alt Text"
              height="300"
              src="/assets/images/consult.jpg" // Replace with your image URL
            />
            <StyledCardContent>
              <styledTypography variant="h6">Consulting Service</styledTypography>
              <styledTypography variant="body2" color="text.secondary">
                Timing: Asar to Isha
              </styledTypography>
            </StyledCardContent>
          </StyledCard>

          {/* Card 5 */}
          <StyledCard >
            <StyledImage
              component="img"
              alt="Image Alt Text"
              height="300"
              src="/assets/images/youth.jpg" // Replace with your image URL
            />
            <StyledCardContent>
              <styledTypography variant="h6">Youth Mentoring Program{" "}</styledTypography>
              <styledTypography variant="body2" color="text.secondary">
                Every Month, 1 Saturday after Isha
                <br />
                For Further Details Call or Whatsapp
              </styledTypography>
            </StyledCardContent>
          </StyledCard>

          
        </StyledDiv>
      </StyledTypography>
    </StyledContainer>
    </>
  )
}

export default Activities
