import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import surahfatiha from "../../assets/audio/surah-fateh.mp3";
import "./HomeAudio.css"; // Import your custom CSS file for HomeAudio styling
import mosquenight from "../../assets/audio/images/mosquenight.jpg"
const HomeAudio = () => {
  return (
    <section className="home-audio-section" >
      <div className="audio-background" style={{backgroundImage:`url(${mosquenight})`}}></div>
      <div className="container">
        <div className="audio-container">
          <div className="audio-player">
            <h3 className="audio-title">Listen to Quran Audio</h3>
            <div className="audio-player-inner">
              <ReactAudioPlayer
                src={surahfatiha}
                autoPlay={false}
                controls
                className="react-audio-player"
              />
            </div>
          </div>
          <div className="audio-content">
            <span className="content-category">Ethical & Moral Beliefs That Guide</span>
            <h2 className="content-title">Welcome To Masjid Zakaria</h2>
            <p className="content-description">Masjid Zakaria is committed to the Islamic way of life based on the Qur’an and the life examples of Prophet Muhammad. We serve as a center for religious development, social, educational, economical, and cultural enrichment.

We believe in the Oneness of God and the oneness of humanity. We are committed to promoting excellence in community life and human dignity throughout our neighborhoods, cities, and country.
            </p>
            <p className="content-description">
            </p>
            <a className="thm-btn" href="/about" title="Learn More">Learn More<span></span><span></span><span></span><span></span></a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeAudio;
