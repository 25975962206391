import React, { useState } from 'react';
import axios from 'axios';
import './AddRecordingModal.css';

const AddRecordingModal = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [recordingData, setRecordingData] = useState({
    title: '',
    file: null,
    date: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'file') {
      setRecordingData((prevData) => ({
        ...prevData,
        file: files[0]
      }));
    } else {
      setRecordingData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append('title', recordingData.title);
      formData.append('file', recordingData.file);
      formData.append('date', recordingData.date);

      const recordingType = 'friday';
      console.log('Submitting data to:', `${apiUrl}/post-recording/${recordingType}`);
      console.log('FormData:', {
      title: recordingData.title,
      file: recordingData.file,
      date: recordingData.date,
});
      await axios.post(`${apiUrl}/post-recording/${recordingType}`, formData, {
        params: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log('Recording added successfully:');
      setSuccessMessage('Recording added successfully');
      setRecordingData({
        title: '',
        file: null,
        date: ''
      });

    } catch (error) {
      console.error('Error adding recording:', error.message);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Add Friday Bayan</h2>
        {successMessage && <p className="success-message">{successMessage}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label style={{ color: 'red' }}>Title:</label>
            <input className='rectext' type="text" name="title" value={recordingData.title} onChange={handleChange} />
          </div>

          <div className="form-group">
            <label style={{ color: 'red' }}>Recording File:</label>
            <input className='recfile' type="file" name="file" onChange={handleChange} />
          </div>

          {recordingData.file && (
            <div className="form-group">
              <label style={{ color: 'red' }}s>Uploaded File:</label>
              <span style={{ color: 'red' }}>{recordingData.file.name}</span>
            </div>
          )}

          <div className="form-group">
            <label style={{ color: 'red' }}>Date:</label>
            <input type="text" name="date" value={recordingData.date} onChange={handleChange} />
          </div>

          {isLoading ? (
            <div className="loader">Loading...</div>
          ) : (
            <button type="submit" className="submit-button">Add Recording</button>
          )}
        </form>
      </div>
    </div>
  );
};

export default AddRecordingModal;
