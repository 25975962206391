import styled from "styled-components";
import { categories } from "../../data";
import CategoryItem from "./CategoryCard";
import { mobile } from "../../responsive";
import backg from "../../assets/audio/images/sec-bg.jpg"

const Container = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
  ${mobile({ padding: "0px", flexDirection: "column" })}
`;

const Categories = () => {
  return (
    <>
      <Container style={{ backgroundImage: `url(${backg})`  }}>
        {categories.map((item) => (
          <CategoryItem item={item} key={item.id} />
        ))}
      </Container>
    </>
  );
};

export default Categories;
