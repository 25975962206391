import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import "./prayerTime.css";

const JamaatTimings = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const staticNames = useMemo(() => [
    { name: "Fajr" },
    { name: "Zuhr" },
    { name: "Asr" },
    { name: "Maghrib" },
    { name: "Isha" },
    { name: "Jumma" }
  ], []);

  const [timings, setTimings] = useState([]);

  useEffect(() => {
    axios.get(`${apiUrl}/jamat-timings`)
      .then(response => {
        const fetchedTimings = staticNames.map(nameObj => {
          const prayerTime = response.data[nameObj.name.toLowerCase()];
          return {
            ...nameObj,
            time: prayerTime || 'Not available'
          };
        });
        setTimings(fetchedTimings);
      })
      .catch(error => {
        console.error("Error fetching jamat timings:", error);
        setTimings(staticNames.map(name => ({
          ...name,
          time: 'Error fetching time'
        })));
      });
  }, [staticNames, apiUrl]);

  return (
    <section>
      <div className="w-100 position-relative">
        <div className="time-wrap2 w-100">
          <div className="row align-items-center">
            <div className="col-md-12 col-sm-12 col-lg-4">
              <div className="time-title w-100">
                <h4 className="mb-0">Jamat Timing at Masjid Zakaria</h4>
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-lg-8">
              <ul className="time-list2 d-flex flex-wrap w-100 mb-0 list-unstyled">
                {timings.map(({ name, time }) => (
                  <li key={name}><span>{name}:</span>{time}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JamaatTimings;
