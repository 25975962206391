import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./navbar.css";
// import backg from "../../assets/audio/images/sec-bg.jpg";
import masjid1 from "../../assets/audio/images/masjid1715.jpg";
import masjid3 from "../../assets/audio/images/masjid3715.jpg";
import masjid4 from "../../assets/audio/images/masjid4715.jpg";

import NavbarAll from './NavbarAll';
import JamaatTimings from '../PrayerTimeComponent/JamatTime';
import ReactTicker from '../ReactTicker/ReactTicker';

const Navbar3 = () => {
  const settings = {
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    speed: 1500,
    draggable: true,
    dots: true,
    pauseOnDotsHover: true,
    pauseOnFocus: false,
    pauseOnHover: false,
  };

  return (
    <>
      <ReactTicker />
      <NavbarAll />
      <JamaatTimings />
      <section>
        <div className="w-100 position-relative">
          <div className="feat-wrap v2 position-relative w-100">
            <Slider {...settings} className="feat-caro">
              {/* Slide 1 */}
              <div className="feat-item v2">
                <div className="feat-img position-absolute" style={{ backgroundImage: `url(${masjid1})`, opacity: "0.9" }}></div>
                <div className="feat-cap-wrap position-absolute d-inline-block">
                  <div className="feat-cap left-icon d-inline-block" style={{ top: "30%" }}>
                    {/* Adjusted top property */}
                    {/* <i className="d-inline-block flaticon-rub-el-hizb thm-clr"></i>
                    <h4 className="mb-0">“Call upon Me,<span className=""> I will respond to you.” –Quran 40:60</span></h4>
                    <p className="mb-0">There is only one God, and that Muhammad is a messenger of God.</p>
                    <Link className="thm-btn thm-bg" to="/seerat" title="">
                      Read About Islam<span></span><span></span><span></span><span></span>
                    </Link> */}
                  </div>
                </div>
              </div>
  
              {/* Slide 2 */}
              {/* Adjusted top property for the second slide */}
              <div className="feat-item v2" style={{ top: "20%" }}>
                <div className="feat-img position-absolute" style={{ backgroundImage: `url(${masjid4})`, opacity: "0.9" }}></div>
                <div className="feat-cap-wrap position-absolute d-inline-block">
                  <div className="feat-cap left-icon d-inline-block">
                    {/* <i className="d-inline-block flaticon-rub-el-hizb thm-clr"></i>
                    <h4 className="mb-0">“Call upon Me,<span className=""> I will respond to you.” –Quran 40:60</span></h4>
                    <p className="mb-0">There is only one God, and that Muhammad is a messenger of God.</p>
                    <Link className="thm-btn thm-bg" to="/seerat" title="">
                      Read About Islam<span></span><span></span><span></span><span></span>
                    </Link> */}
                  </div>
                </div>
              </div>
  
              {/* Slide 3 */}
              {/* Adjusted top property for the third slide */}
              <div className="feat-item v2" style={{ top: "10%" }}>
                <div className="feat-img position-absolute" style={{ backgroundImage: `url(${masjid3})`, opacity: "0.9" }}></div>
                <div className="feat-cap-wrap position-absolute d-inline-block">
                  <div className="feat-cap left-icon d-inline-block">
                    {/* <i className="d-inline-block flaticon-rub-el-hizb thm-clr"></i>
                    <h4 className="mb-0">“Call upon Me,<span className=""> I will respond to you.” –Quran 40:60</span></h4>
                    <p className="mb-0">There is only one God, and that Muhammad is a messenger of God.</p>
                    <Link className="thm-btn thm-bg" to="/seerat" title="">
                      Read About Islam<span></span><span></span><span></span><span></span>
                    </Link> */}
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
  }
export default Navbar3;
