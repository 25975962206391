import React from "react";
import "./home.css";

// Replace these URLs with the actual URLs provided by ImageKit for your videos
const video1Url = "https://ik.imagekit.io/zakariamasjid/jummabayan1.mp4?updatedAt=1707675296837";


const HomePageView = () => {
  return (
    <>
      <div>
        <h1 className="categorybayanh1" style={{textAlign:"center", marginTop:"25px", textDecoration:"underline", border:"5px solid black", borderRadius:"10px"}}>Latest Bayan</h1>
      </div>
      <div className="video-container" style={{backgroundColor:"#252a2c"}}>
        <div className="videocard" style={{marginTop:"50px"}}>
          <video width="90%" height="400px" controls preload="auto" playsInline  loop autoplay>
            <source src={video1Url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        
      </div>
    </>
  );
};

export default HomePageView;
