import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";

const Container = styled.div`
  max-width: 800px;
  margin: auto;
  padding: 20px;
`;

const Heading = styled.h3`
  text-align: center;
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    display: block;
    overflow-x: auto;
  }
`;

const TableHeader = styled.th`
color: #10104e;
  padding: 10px;
  text-align: center;
  font-size: 16px;
  border-bottom: 1px solid #ccc;
`;

const TableRow = styled.tr`
color: #080878;
  border-bottom: 1px solid #ccc;
  &:last-child {
    border-bottom: none;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: center;
  font-size: 16px;
  border-right: 1px solid #ccc;
  &:last-child {
    border-right: none;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-bottom: 10px;
    width: 100%;
  }
`;

const UpdateJamaatTimings = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const staticNames =  useMemo(() => ["Fajr", "Zuhr", "Asr", "Maghrib", "Isha"],[]);

  const [timings, setTimings] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    axios
      .get(`${apiUrl}/jamat-timings`)
      .then((response) => {
        if (typeof response.data === "object") {
          const updatedTimings = staticNames.map((name) => ({
            name,
            time: response.data[name.toLowerCase()] || "Not available",
          }));
          setTimings(updatedTimings);
        } else {
          console.error(
            "Invalid API response format. Expected an object."
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching jamat timings:", error);
      });
  }, [staticNames, apiUrl]);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleConfirmClick = () => {
    const formattedTimings = timings.reduce((acc, timing) => {
      acc[timing.name.toLowerCase()] = timing.time;
      return acc;
    }, {});

    axios
      .put(`${apiUrl}/jamat-timings`, formattedTimings)
      .then((response) => {
        // console.log(response.data);
        // console.log("Updated successfully");
        setIsEditing(false);
      })
      .catch((error) => {
        // console.error("Error updating jamat timings:", error);
      });
  };

  const handleInputChange = (index, value) => {
    const updatedTimings = [...timings];
    updatedTimings[index].time = value;
    setTimings(updatedTimings);
  };

  const addBayan = (type) => {
    navigate(`/${type === 'morning' ? 'newbayanmorning' : 'newbayan'}`);
  };
  const deletebayan = () => {
    navigate("/update-delete-morningfridaybayan")
  }
  const deleteSundaybayan = () => {
    navigate("/update-delete-morningSundaybayan")
  }
  return (
    <Container>
      <Heading>Jamat Timings</Heading>
      <Button onClick={handleEditClick}>
        {isEditing ? "Cancel Edit" : "Edit"}
      </Button>
      <Button onClick={handleConfirmClick} disabled={!isEditing}>
        Confirm
      </Button>
      <Table>
        <thead>
          <TableRow>
            {staticNames.map((name, index) => (
              <TableHeader key={index}>{name}</TableHeader>
            ))}
          </TableRow>
        </thead>
        <tbody>
          <TableRow>
            {timings.map((timing, index) => (
              <TableCell key={index}>
                {isEditing ? (
                  <input
                    type="text"
                    value={timing.time}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                  />
                ) : (
                  timing.time
                )}
              </TableCell>
            ))}
          </TableRow>
        </tbody>
      </Table>
      <Button onClick={() => addBayan('friday')}>
        Add Friday Bayan
      </Button>
      <Button onClick={() => addBayan('morning')}>
        Add Morning Dars Bayan
      </Button>
      <Button onClick={() => deletebayan()}>
        Delete Friday Bayan
      </Button>
      <Button style={{marginTop:"25px"}} onClick={() => deleteSundaybayan()}>
        Delete Morning Dars Bayan
      </Button>
    </Container>
  );
};

export default UpdateJamaatTimings;
