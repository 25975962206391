import React from 'react'
import Marquee from "react-fast-marquee";
import { useState,useEffect,useMemo } from 'react';
import axios from 'axios';
const ReactTicker = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const staticNames = useMemo(() => [
    { name: "Fajr" },
    { name: "Zuhr" },
    { name: "Asr" },
    { name: "Maghrib" },
    { name: "Isha" },
    { name: "Jumma" }
  ], []);

  const [timings, setTimings] = useState([]);

  useEffect(() => {
    axios.get(`${apiUrl}/jamat-timings`)
      .then(response => {
        const fetchedTimings = staticNames.map(nameObj => {
          const prayerTime = response.data[nameObj.name.toLowerCase()];
          return {
            ...nameObj,
            time: prayerTime || 'Not available'
          };
        });
        setTimings(fetchedTimings);
      })
      .catch(error => {
        console.error("Error fetching jamat timings:", error);
        setTimings(staticNames.map(name => ({
          ...name,
          time: 'Error fetching time'
        })));
      });
      
  }, [staticNames, apiUrl]);
  return (
    <>
    <div >
      <Marquee style={{backgroundColor:"black"}}>
    {/* <h5>مرحبا بكم في مسجد زكريا</h5> */}
    {timings.map(({ name, time }) => (
                  <h6 style={{ color: "white" }} key={name}>
                  <span>{"   "}{" "}{name}{" "}:</span>{" "}{time}{" "}{" ,\u00A0\u00A0"}
                </h6>
                
                ))}

  </Marquee>
    </div>
   
    </>
  )
}

export default ReactTicker
