import React, { useState, useEffect } from 'react';
import NavbarAll from '../../NavbarAll/NavbarAllFiles';
import ReactPlayer from 'react-player';
import axios from 'axios';

const UpdateandDeleteFridayBayan = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [pageNumber, setPageNumber] = useState(1);
  const [preloaderVisible, setPreloaderVisible] = useState(true);
  const bayansPerPage = 12;
  const [fridayBayans, setFridayBayans] = useState([]);
  const [bayantCount, setBayantCount] = useState(0);

  useEffect(() => {
    const fetchFridayBayans = async () => {
      try {
        // Fetch count of Friday Bayans
        const response1 = await axios.get(`${apiUrl}/get-recordings-count/friday`);
        setBayantCount(response1.data.bayantCount);

        // Fetch Friday Bayans for the current page
        const response = await axios.get(`${apiUrl}/get-recordings/friday`, {
          params: {
            page: pageNumber
          }
        });
        setFridayBayans(response.data);
        setPreloaderVisible(false);
      } catch (error) {
        setPreloaderVisible(false);
        console.error('Error fetching Friday bayans:', error.message);
      }
    };

    fetchFridayBayans();
  }, [apiUrl, pageNumber]);

  // Function to handle pagination
  const paginate = (pageNumber) => {
    setPreloaderVisible(true);
    setPageNumber(pageNumber);
  };

  // Function to delete a recording
  const deleteRecording = async (recordingId) => {
    if (window.confirm('Are you sure you want to delete this recording?')) {
      try {
        await axios.delete(`${apiUrl}/delete-recording/${recordingId}`);
        // Assuming you want to refresh the list after deletion, you may fetch the data again
         // Or update the state to remove the deleted recording from `fridayBayans`
      } catch (error) {
        console.error('Error deleting recording:', error.message);
        // Handle error
      }
    }
  };

  return (
    <>
      {preloaderVisible && (
        <div id="preloader">
          <div className="preloader-inner">
            <i className="preloader-icon thm-clr flaticon-kaaba"></i>
          </div>
        </div>
      )}
      <NavbarAll />
      <div className="container">
        <div className="plyr v4 w-100">
          {fridayBayans.map((bayan, index) => (
            <div key={index} className="audio-container" style={{ border: "2px solid black", backgroundColor: "white", color: "black", marginBottom: "15px" }}>
              <div className="audio-info" style={{ display: "flex" }}>
                <p className="audio-title" style={{ color: "black", fontWeight: "bold", fontSize: "16px" }}>Topic: {bayan.title}</p>
                <p className="audio-title" style={{ color: "black", fontWeight: "bold", fontSize: "16px", marginLeft: "8px" }}>Date: {bayan.date}</p>
              </div>
              <ReactPlayer
                url={encodeURI(bayan.downloadURL)}
                controls
                width="100%"
                height="50px"
              />
              <button
                style={{ backgroundColor: "red", color: "white", marginTop: "8px", cursor: "pointer" }}
                onClick={() => deleteRecording(bayan.id)}
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      </div>
      {fridayBayans.length > 0 && (
        <ul className="pagination" style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", padding: 0, listStyleType: "none" }}>
          <li className="page-item" style={{ marginRight: "50px" }}>
            <button style={{ backgroundColor: "black", color: "white" }}
              className="page-link"
              onClick={() => paginate(pageNumber - 1)}
              disabled={pageNumber === 1}
            >
              Previous
            </button>
          </li>
          {Array.from({ length: Math.ceil(bayantCount / bayansPerPage) }, (_, index) => index + 1).map((number, index, arr) => {
            if (number === 1 || number === arr.length || (number >= pageNumber - 1 && number <= pageNumber + 1)) {
              return (
                <li key={index} className={`page-item ${pageNumber === number ? 'active' : ''}`} style={{ margin: "5px" }}>
                  <button
                    style={{
                      backgroundColor: pageNumber === number ? "blue" : "black",
                      color: "white"
                    }}
                    onClick={() => paginate(number)}
                    className="page-link"
                  >
                    {number}
                  </button>
                </li>
              );
            } else if ((number === 2 && pageNumber > 3) || (number === arr.length - 1 && pageNumber < arr.length - 2)) {
              return <li key={index} style={{ marginRight: "20px", border: "2px solid black", padding: "4px 8px" }}>...</li>;
            } else {
              return null;
            }
          })}
          <li className="page-item" style={{ margin: "5px" }}>
            <button style={{ backgroundColor: "black", color: "white" }}
              className="page-link"
              onClick={() => paginate(pageNumber + 1)}
              disabled={pageNumber === Math.ceil(bayantCount / bayansPerPage)}
            >
              Next
            </button>
          </li>
        </ul>
      )}
    </>
  );
};

export default UpdateandDeleteFridayBayan;
