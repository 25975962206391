import React from "react";
import Card from "react-bootstrap/Card";
import NavbarAll from '../NavbarAll/NavbarAllFiles';
// import { Typography, Container } from "@mui/material";
// import ListItemComponent from "../ListItemComponent/listItem2";
// import YoutubeComponent from "../YoutubeComponent/youtube";
const FridayBayanComponent = () => {
   
  return (
    <>
     <NavbarAll />
            <div className="video-container">
          
      <Card className="mufti-taqi-card" >
        <Card.Body>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/pLKwG1BDrZg?si=FwYunIKaYa_AcP2c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/Tr2ZptreJig?si=cZsQRnqcBN6Dn5hd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/BPYlHKRtZtg?si=zMx36tQogPW4zD_j" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/s5CQox0pY5Q?si=8UJm9rVAv4485HXv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </Card.Body>
      </Card>
    </div>
    </>
  );
};

export default FridayBayanComponent;
