export const categories = [
  {
    id: 1,
    img: "https://ik.imagekit.io/zakariamasjid/quran11.jpg?updatedAt=1707842277661",
    title: "Books",
    link: "/seerat",
  },
  {
    id: 2,
    img: "https://ik.imagekit.io/zakariamasjid/masjidnabwiimg.jpg?updatedAt=1707842276195",
    title: "Bayan",
    link: "/hazrat",
  },
  {
    id: 3,
    img: "https://ik.imagekit.io/zakariamasjid/makkahimg.jpg?updatedAt=1707842280447",
    title: "Naat",
    link: "/naat",
  },
];
export const hazratBayan = [
  {
    id: 1,
    img: "/assets/images/hazrat.jpg",
    title: "Moulana Abdul Sattar",
    link: "/hazratbayan",
  },
  {
    id: 2,
    img: "/assets/images/muftitaqi1.webp",
    title: "Mufti Taqi Usmani Sahab",
    link: "/muftiTaqi",
  },
];
