import React from 'react'
import Masjid1 from "../../assets/audio/images/masjid1.jpg";
import Masjid3 from "../../assets/audio/images/masjid3.jpg";
import Masjid4 from "../../assets/audio/images/masjid4.jpg";
import Friday from "../../assets/audio/images/fridaybayan.jpg";
// import Mosqueimg from "../../assets/audio/images/mosqueaudio.jpg"s
// import Logo1 from "../../assets/audio/images/logo185.jpg"
import { Link } from 'react-router-dom';
import { useState,useEffect } from 'react';
import NavbarAll from '../NavbarAll/NavbarAllFiles';
const About = () => {
    const [preloaderVisible, setPreloaderVisible] = useState(true);
    useEffect(() => {
          try {
            setPreloaderVisible(false); // Set preloader visibility to false
            // console.log("Seerat data fetched successfully");
          } catch (error) {
            setPreloaderVisible(false);
            // console.error('Error fetching Seerat data:', error.message);
          }
      }, [preloaderVisible]);
    
  return (
   <>
    <NavbarAll/>
            <section>
                <div class="w-100 pt-80 black-layer pb-70 opc65 position-relative">
                    <div class="fixed-bg" style={{backgroundImage: `url(${Friday})`}}></div>
                    <div class="container">
                        <div class="page-title-wrap text-center w-100">
                            <div class="page-title-inner d-inline-block">
                                <h1 class="mb-0">About Mosque</h1>
                                <ol class="breadcrumb mb-0">
                                    <li class="breadcrumb-item"><Link to="/" title="Home">Masjid - Home</Link></li>
                                    <li class="breadcrumb-item active">About</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="w-100 pt-120 pb-280 position-relative">
                    <img class="sec-botm-rgt-mckp img-fluid position-absolute" src="images1/sec-botm-mckp.png" alt="" />
                    <div class="container">
                        <div class="event-wrap w-100">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-lg-4">
                                    <div class="event-box w-100">
                                        <div class="event-img overflow-hidden position-relative w-100">
                                            <Link to="" title=""><img class="img-fluid w-100" src={Masjid1} alt="Mosque images"/></Link>
                                        </div>
                                        <div class="event-info pat-bg gray-layer opc85 position-relative back-blend-multiply gray-bg w-100" style={{backgroundImage: `url(${Masjid3})`}}>
                                            <span class="event-loc d-block thm-clr"></span>
                                            <h3 class="mb-0"> </h3>
                                           
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-lg-4">
                                    <div class="event-box w-100">
                                        <div class="event-img overflow-hidden position-relative w-100">
                                            <Link to="" title=""><img class="img-fluid w-100" src={Masjid3} alt="Event  2"/></Link>
                                        </div>
                                        <div class="event-info pat-bg gray-layer opc85 position-relative back-blend-multiply gray-bg w-100" style={{backgroundImage: `url(${Masjid1})`}}>
                                            <span class="event-loc d-block thm-clr"></span>
                                            <h3 class="mb-0"> </h3>
                                            <span class="event-time d-block thm-clr"></span>
                                            <span class="event-price d-block"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-lg-4">
                                    <div class="event-box w-100">
                                        <div class="event-img overflow-hidden position-relative w-100">
                                            <Link to="" title=""><img class="img-fluid w-100" src={Masjid4} alt="Event 3"/></Link>
                                        </div>
                                        <div class="event-info pat-bg gray-layer opc85 position-relative back-blend-multiply gray-bg w-100" style={{backgroundImage: `url(${Masjid1})`}}>
                                            <span class="event-loc d-block thm-clr"></span>
                                            <h3 class="mb-0"> </h3>
                                            <span class="event-time d-block thm-clr"></span>
                                            <span class="event-price d-block"></span>
                                        </div>
                                    </div>
                                </div>
                               
                                
                            </div>
                        </div>
                       
                    </div>
                </div>
            </section>
   </>
  )
}

export default About
