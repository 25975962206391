
import React from "react";
import About from "./About";

const AskHazratComponent = () => {
  return (
    <>
    <About/>
      
    </>
  );
};

export default AskHazratComponent;
