import React from "react";
import Books410 from "../../assets/audio/images/books410.jpg"
import { Link } from "react-router-dom";
// import Logo1 from "../../assets/audio/images/logo185.png"
import  { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import NavbarAll from '../NavbarAll/NavbarAllFiles';
const SeeratComponent = () => {
  const [preloaderVisible, setPreloaderVisible] = useState(true);
  useEffect(() => {
        try {
          setPreloaderVisible(false); // Set preloader visibility to false
          // console.log("Seerat data fetched successfully");
        } catch (error) {
          setPreloaderVisible(false);
          // console.error('Error fetching Seerat data:', error.message);
          // console.log(preloaderVisible)
        }
    }, [preloaderVisible]);
   
  
    
  
    
  const seeratBooks = [
    {
      title: "Uswa-E-Rasool-E-Akram-Sallallahu-Alaihi-Wasallam by Dr Abdul Hai Aarafi(DB)",
      coverImagePath: "/assets/images/uswah.jpg",
      pdfPath: "/assets/images/USWAERASOOL.pdf",
    },
    {
      title: "What You Sow Shall You Reap | Moulana Abdul Sattar",
      coverImagePath: "/assets/images/whatyousowimg.jpg",
      pdfPath: "/assets/images/whatyousow.pdf",
    },
    {
      title: "Asaan Tarjuma e Quran English Version | Mufti Taqi Usmani DB",
      coverImagePath: "/assets/images/asaantarjumaquran.jpeg",
      pdfPath: "/assets/images/asaantarjumaquran.pdf",
    },
    {
      title: "Importance Of Prayer | Moulana Abdul Sattar",
      coverImagePath: "/assets/images/importanceofprayerimg.jpg",
      pdfPath: "/assets/images/importanceofprayer.pdf",
    },
    {
      title: "MAARIF UL HADITH VOL 1 - MUHAMMED MANZOR NOMANI",
      coverImagePath: "/assets/images/maarifulhadithimg.png",
      pdfPath: "/assets/images/maarifulhadith.pdf",
    },
    {
      title: "MAARIF UL HADITH VOL 2 - MUHAMMED MANZOR NOMANI",
      coverImagePath: "/assets/images/maarifulhadithimg.png",
      pdfPath: "/assets/images/maarifulhadith02.pdf",
    },
    // Add more books as needed
  ];

  const handleBookClick = (pdfPath) => {
    window.open(pdfPath);
  };

  return (
    <>
       <Helmet>
    <title>Books</title>
    <meta name="description" content="
Books at Masjid Zakaria aim to provide religious texts such as the Quran, Hadith collections, and scholarly works on Islamic jurisprudence, theology, and spirituality. They aim to facilitate learning, deepen understanding of Islamic teachings, and serve as resources for personal study, reference, and educational programs within the mosque community." />
  </Helmet> 
   <NavbarAll/>

      <section>
        <div className="w-100 pt-100 black-layer pb-100 opc1 position-relative">
          <div className="fixed-bg" style={{ backgroundImage: "url(images1/sec-bg2.jpg)" }}></div>
          <div className="hlf-bg thm-layer opc6  back-blend-multiply position-absolute" style={{backgroundColor:"green"}}></div>
          <div className="container">
            <div className="learn-wrap w-100">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-lg-5">
                  <div className="learn-img w-100">
                    <img className="img-fluid w-100" src={Books410} alt="LearnImage" />
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 col-lg-7">
                  <div className="learn-inner w-100">
                    <div className="learn-top d-flex flex-wrap align-items-center justify-content-between w-100">
                      <div className="learn-top-inner">
                        <h3 className="mb-0"><i className="flaticon-rub-el-hizb thm-clr"></i>Books </h3>
                      </div>
                      <div className="learn-top-inner">
                      </div>
                    </div>
                    <div className="learn-post-wrap w-100">
                      <div className="row">
                        {seeratBooks.map((book, index) => (
                          <div key={index} className="col-md-6 col-sm-6 col-lg-6">
                            <div className="learn-post d-flex flex-wrap align-items-center">
                              <div className="learn-post-img">
                                <img className="img-fluid w-100" src={book.coverImagePath} alt={book.title} />
                              </div>
                              <div className="learn-post-info">
                                <h4 className="mb-0"><Link to="/" title={book.title}>{book.title}</Link></h4>
                                <span className="d-block"></span>
                                <Link to={book.pdfPath} title={`Download ${book.title} PDF`} onClick={() => handleBookClick(book.pdfPath)}>
                                  Download PDF<i className="fas fa-angle-double-right"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SeeratComponent;
